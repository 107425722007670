/* eslint-disable no-console */
import { EventEmitter, Injectable } from '@angular/core';

import { AppInfoConfig, DeviceService, UserService, WindowRef } from '@frontend/vanilla/core';

import { DeviceManager } from '../../../shared/device-manager.service';
import { GeoLocationClientConfig } from '../../client-config/client-config.models';
import { ConfigProviderService } from '../../client-config/config-provider.service';
import {
    CoordinatorStates,
    ICoordinatorProbeResult,
    IErrorData,
    IInitializationInput,
    IIntegrationApp,
    OnError,
    OnInitialized,
    OnProbed,
    OnUninitialized,
    Optional,
    PLCIdentifier,
} from '../coordinator.models';

@Injectable({
    providedIn: 'root',
})
export class GeolocationCoordinatorService implements IIntegrationApp {
    private geolocationCoordinator: Optional<IIntegrationApp>;
    private geolocationConfig: GeoLocationClientConfig;
    private _initializePending: boolean = false;
    private _uninitializePending: boolean = false;
    private _initializationInput: IInitializationInput | undefined | null;
    constructor(
        private windowRef: WindowRef,
        private deviceManager: DeviceManager,
        private deviceservice: DeviceService,
        private readonly userService: UserService,
        private readonly configProviderService: ConfigProviderService,
        private readonly appInfoConfig: AppInfoConfig,
    ) {
        this.geolocationConfig = this.configProviderService.provideGeoLocationClientConfig();
        this.hookupCoordinator();
        addEventListener(
            'geolocationcoordinator:bootstrapped',
            () => {
                this.hookupCoordinator();
            },
            {
                once: true,
            },
        );
    }

    onAvailable: EventEmitter<GeolocationCoordinatorService>;

    get appDownloadUrl(): string {
        return this.geolocationCoordinator?.appDownloadUrl ?? '';
    }

    probe(): void {
        this.geolocationCoordinator?.probe();
    }

    initialize(input?: IInitializationInput | null): void {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.initialize({
                session: (input && input.session) || (this.userService && this.userService.ssoToken) || 'NotAvailable',
                channelId: (input && input.channelId) || '',
                plcIdentifier: (input && input.plcIdentifier) || 'geocomply',
                showPLCPopup: input?.showPLCPopup ?? false,
                productId: (input && input.productId) || this.appInfoConfig.product || 'RACEBOOK',
            });
        } else {
            this._initializePending = true;
            this._initializationInput = input;
        }
    }

    uninitialize(): void {
        if (this.geolocationCoordinator) {
            this.geolocationCoordinator.uninitialize();
        } else {
            this._uninitializePending = true;
        }
    }
    set onInitialized(value: OnInitialized<CoordinatorStates>) {
        if (this.geolocationCoordinator?.onInitialized) {
            this.geolocationCoordinator.onInitialized = value;
        }
    }

    set onUninitialized(value: OnUninitialized) {
        if (this.geolocationCoordinator?.onUninitialized) {
            this.geolocationCoordinator.onUninitialized = value;
        }
    }

    set onError(value: OnError) {
        if (this.geolocationCoordinator?.onError) {
            this.geolocationCoordinator.onError = value;
        }
    }

    set onProbed(value: OnProbed) {
        if (this.geolocationCoordinator?.onProbed) {
            this.geolocationCoordinator.onProbed = value;
        }
    }

    registerUnload() {
        this.windowRef.nativeWindow.onunload = () => {
            console.info('Window onunload::');
            this.uninitialize();
        };
        this.windowRef.nativeWindow.removeEventListener('message', this.coordinationSynchronizer);
    }

    registerSynchronization() {
        this.geolocationConfig = this.configProviderService.provideGeoLocationClientConfig();
        if (this.geolocationConfig.isEnabled && this.geolocationConfig.synchronizeCoordination) {
            this.windowRef.nativeWindow.addEventListener('message', this.coordinationSynchronizer);
        }
    }

    getPLCIdentifier(): PLCIdentifier {
        if (this.deviceManager.isTouch()) {
            if (this.deviceservice.isiOS) {
                return 'oobeeiOS';
            }
            return 'oobeeAndroid';
        }
        return 'geocomply';
    }

    private onGeolocationError(errorData: IErrorData): void {
        if (this.onError) {
            this.onError(errorData);
        }
    }

    private onGeolocationInitialized(initializationData?: CoordinatorStates | null): void {
        if (this.onInitialized) {
            this.onInitialized(initializationData);
        }
    }

    private onGeolocationUninitialized(): void {
        if (this.onUninitialized) {
            this.onUninitialized();
        }
    }

    private onGeolocationProbed(probeResult: ICoordinatorProbeResult): void {
        if (this.onProbed) {
            this.onProbed(probeResult);
        }
    }

    private hookupCoordinator(): void {
        const geolocationCoordinator = (window as any).geolocationCoordinator as IIntegrationApp;
        if (geolocationCoordinator) {
            this.geolocationCoordinator = geolocationCoordinator;
            this.geolocationCoordinator.onError = this.onGeolocationError.bind(this);
            this.geolocationCoordinator.onInitialized = this.onGeolocationInitialized.bind(this);
            this.geolocationCoordinator.onUninitialized = this.onGeolocationUninitialized.bind(this);
            this.geolocationCoordinator.onProbed = this.onGeolocationProbed.bind(this);
            this.onAvailable && this.onAvailable.emit(this);

            if (this._initializePending) {
                this._initializePending = false;
                this.initialize(this._initializationInput);
                this._initializationInput = null;
                return;
            }
            if (this._uninitializePending) {
                this._uninitializePending = false;
                this.uninitialize();
            }
        }
    }

    private readonly coordinationSynchronizer = (messageEvent: MessageEvent): void => {
        if (this.geolocationConfig.isEnabled && this.geolocationConfig.synchronizeCoordination) {
            if (
                messageEvent &&
                messageEvent.data &&
                messageEvent.data.trim &&
                messageEvent.data.trim() === this.geolocationConfig.synchronizationEvent.trim()
            ) {
                this.geolocationCoordinator?.uninitialize();
            }
        }
    };
}
