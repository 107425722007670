import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { PopperContentComponent } from '@frontend/vanilla/features/popper';
import { NgxFloatUiModule } from 'ngx-float-ui';

import { GeolocationInstallerComponent } from './installer.component';

@NgModule({
    imports: [MatDialogModule, CommonModule, DynamicHtmlDirective, NgxFloatUiModule, PopperContentComponent],
    // declarations: [GeolocationInstallerComponent, GeolocationCoordinationStarterComponent],
    declarations: [GeolocationInstallerComponent],
    exports: [GeolocationInstallerComponent],
    // entryComponents: [GeolocationInstallerComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class GeolocationInstallerModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(GeolocationInstallerComponent);
    }

    public static forRoot(): ModuleWithProviders<GeolocationInstallerModule> {
        return {
            ngModule: GeolocationInstallerModule,
            providers: [MatDialogModule],
        };
    }
}
