import { ModuleWithProviders, NgModule } from '@angular/core';

import { SitecoreImageResizePipe } from './sitecore-image-resize.pipe';

@NgModule({
    imports: [],
    declarations: [SitecoreImageResizePipe],
    exports: [SitecoreImageResizePipe],
})
export class PipesModule {
    public static forRoot(): ModuleWithProviders<PipesModule> {
        return {
            ngModule: PipesModule,
            providers: [],
        };
    }
}
