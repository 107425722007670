/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { Coordinates, GeolocationPosition, GeolocationService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { __GEOLOCATION_AVAILABLE__, __READ_CURRENT_GEOLOCATION__ } from './constants';
import { ConfigProviderService } from './lib/client-config/config-provider.service';
import { IUserLocation } from './partner-token.model';
import { CacheManagerService } from './shared/cache.manager.service';

@Injectable({
    providedIn: 'root',
})
export class GeolocationHandlerService {
    private userLocation: IUserLocation;
    private locationAvailableSubject = new BehaviorSubject<boolean>(false);
    locationAvailable: Observable<boolean> = this.locationAvailableSubject.asObservable();

    constructor(
        private readonly geolocationService: GeolocationService,
        private readonly cachemanager: CacheManagerService,
        private configProvider: ConfigProviderService,
    ) {
        if (this.configProvider?.geoLocationClientConfig?.useVanilla) {
            this.geolocationService.whenReady.subscribe(() => {
                this.geolocationService.watchBrowserPositionChanges();
                this.geolocationService.positionChanges.subscribe(
                    (currentPosition: GeolocationPosition) => {
                        this.setLocation(currentPosition.coords);
                        this.cachemanager.setGeolocationCoordinates(currentPosition?.coords?.latitude, currentPosition.coords.longitude);
                        this.locationAvailableSubject.next(true);
                        dispatchEvent(
                            new CustomEvent(__GEOLOCATION_AVAILABLE__, {
                                detail: { location: currentPosition },
                            }),
                        );
                    },
                    (error: Error) => {
                        console.error('[RacingExtension]', '[Geolocation]', '[Error]', error);
                    },
                );
            });
        }
    }

    registerHandler(): void {
        addEventListener(__READ_CURRENT_GEOLOCATION__, (customEvent: CustomEventInit) => {
            if (customEvent.detail && customEvent.detail.currentLocation) {
                customEvent.detail.currentLocation = this.geolocationService.currentPosition;
            }
        });
    }

    getLocation(): IUserLocation {
        return this.userLocation;
    }
    setLocation(currentLocation: Coordinates): void {
        this.userLocation = {
            latitude: currentLocation.latitude,
            longitude: currentLocation.longitude,
            accuracy: currentLocation.accuracy,
        };
    }
}
