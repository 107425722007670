<a href="{{ teaser?.teaserLink?.url }}" target="{{ teaser?.teaserLink?.attributes?.target }}">
    <div
        class="opt-teaser-body"
        [style.height.px]="isMobileImage ? mobileViewTeaserHeight : isTabletView ? tabletViewTeaserHeight : desktopViewTeaserHeight">
        <div
            class="opt-teaser-bg-image"
            [style.background-position]="teaserBackgroundPosition"
            [ngStyle]="{ 'background-image': 'url(' + (teaser.backgroundImage.src | sitecore_image_resize) + ')' }"
            *ngIf="teaser.backgroundImage && isMobileImage && !teaser.isVideoUrl"></div>

        <div
            class="opt-teaser-bg-image"
            [ngStyle]="{ 'background-image': 'url(' + (teaser.desktopImage.src || teaser.backgroundImage.src | sitecore_image_resize) + ')' }"
            *ngIf="teaser.desktopImage && !isMobileImage && !teaser.isVideoUrl"></div>
        <div [ngClass]="'opt-teaser-text-' + (teaser.textHorizontalPosition | lowercase)">
            <div class="opt-teaser-info" [ngClass]="'text-align-' + (horizontalAlignment | lowercase)">
                <hr-teaser-text-position
                    [teaserVerticalPosition]="teaser.textVerticalPosition"
                    [teaserTitle]="teaser.teaserTitle"
                    [teaserSubTitle]="teaser.subTitle"
                    [teaser]="teaser">
                </hr-teaser-text-position>
                <div class="opt-teaser-cta" *ngIf="teaser?.cta">
                    <a
                        class="btn btn-primary"
                        href="{{ teaser.cta.url }}"
                        [innerHtml]="teaser.cta.text | trustAsHtml"
                        target="{{ teaser.cta.attributes.target }}"></a>
                </div>
            </div>
        </div>
    </div>
</a>
