import { Injectable } from '@angular/core';

import { ApiBase, ApiServiceFactory } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
    deps: [ApiServiceFactory],
    useFactory: apiServiceFactory,
})
export class ApiService extends ApiBase {}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory) {
    return apiServiceFactory.create(ApiService, { product: 'racebook', area: 'horseracing', forwardProductApiRequestHeader: true });
}
