import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CacheManagerService {
    setGeolocationCoordinates(latitude: number | undefined | null = null, longitude: number | undefined | null = null) {
        try {
            localStorage.setItem('Latitude', latitude?.toString() ?? '');
            localStorage.setItem('Longitude', longitude?.toString() ?? '');
        } catch (error) {}
    }
}
