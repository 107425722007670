import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';

import { DynamicHtmlDirective } from '@frontend/vanilla/core';
import { TrustAsHtmlPipe } from '@frontend/vanilla/shared/browser';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';

import { PipesModule } from '../../shared/pipes/pipes.module';
import { TeaserItemComponent } from './teaser-item/teaser-item.component';
import { TeaserTextPositionComponent } from './teaser-item/teaser-text-position.component';
import { TeaserComponent } from './teaser.component';

@NgModule({
    imports: [CommonModule, PipesModule, SwiperComponent, TrustAsHtmlPipe, DynamicHtmlDirective],
    declarations: [TeaserComponent, TeaserItemComponent, TeaserTextPositionComponent],
    exports: [TeaserComponent, TeaserItemComponent, TeaserTextPositionComponent],
})
export class TeaserModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(TeaserComponent);
        appRef.bootstrap(TeaserItemComponent);
        appRef.bootstrap(TeaserTextPositionComponent);
    }
}
