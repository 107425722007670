<div *ngIf="((!isAuthenticated && showHelpConfig && isMobile) || !isMobile) && helpConfig?.enableHelpPopUp && helpPopup !== null">
    <div *ngIf="isMobile" style="margin-bottom: 2%">
        <div *ngFor="let item of helpPopup.menuItems; trackBy: trackByMenuItems">
            <div class="border" (click)="open(item.header)">
                <span>{{ item.header }}</span>
                <span class="theme-right"></span>
            </div>
        </div>
    </div>
    <div class="understand" *ngIf="!isMobile">
        <div *ngFor="let item of helpPopup.menuItems; trackBy: trackByMenuItems">
            <div class="border DesktopBorder" (click)="open(item.header)">
                <div class="understandText">
                    <span class="HeaderText">{{ item.header }}</span>
                    <span class="OverFlowText">{{ item.description }}</span>
                </div>
                <span class="theme-right arrow"></span>
            </div>
        </div>
    </div>
</div>
