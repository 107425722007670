import { Component, Input } from '@angular/core';

@Component({
    selector: 'hr-teaser-text-position',
    templateUrl: 'teaser-text-position.component.html',
    styleUrls: [],
})
export class TeaserTextPositionComponent {
    @Input() teaserVerticalPosition: string;
    @Input() teaserTitle: string;
    @Input() teaserSubTitle: string;
    @Input() teaser: any;
}
