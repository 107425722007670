<div *ngIf="!isAuthenticated && (showHelpConfig || !isMobile) && loginConfig?.enableLogin && loginBanner">
    <div class="LoginClose" *ngIf="isPopUp">
        <div class="btn-close" (click)="close()"><i class="theme-ex"></i></div>
    </div>
    <div class="center">
        <h2>{{ loginBanner?.Title }}</h2>
        <p class="Description">{{ loginBanner?.Description }}</p>
        <div class="form-element" *ngIf="isPopUp">
            <input
                type="checkbox"
                #IsChecked
                (click)="DoNotShowMessage($event, IsChecked)"
                name="MessageAgain"
                id="MessageAgain"
                class="custom-control-input custom-control-checkbox" />
            <label for="MessageAgain">{{ loginBanner?.CheckBoxText }}</label>
        </div>
        <button type="button" id="login" class="btn-login" (click)="login()">{{ loginBanner?.ButtonText }}</button>
        <a id="signup" class="btn btn-link" (click)="signUpNavigation()">{{ loginBanner?.SignUpText }}</a>
    </div>
</div>
