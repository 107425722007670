/* eslint-disable no-console */
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, LoginDialogService, LoginNavigationService, UserLoginEvent, UserLoginFailedEvent, UserService } from '@frontend/vanilla/core';
import { filter } from 'rxjs/operators';

import { LoginAndHelpService } from '../../login-and-help.service';
import { LoginBannerClientConfig } from '../client-config/client-config.models';
import { ConfigProviderService } from '../client-config/config-provider.service';

@Component({
    selector: 'hrl-horse-racing-login',
    templateUrl: 'horse-racing-login.component.html',
})
export class HorseRacingLoginComponent implements OnInit {
    loginConfig: LoginBannerClientConfig;
    loginBanner: any;
    isAuthenticated: boolean;
    isPopUp: boolean = false;
    showHelpConfig: boolean = false;
    isMobile: boolean;

    constructor(
        private configProvider: ConfigProviderService,
        private loginService: LoginAndHelpService,
        private loginNavigationService: LoginNavigationService,
        private loginDialogService: LoginDialogService,
        private user: UserService,
        public dialogRef: MatDialogRef<HorseRacingLoginComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private activeRoute: ActivatedRoute,
        private deviceService: DeviceService,
    ) {
        this.isAuthenticated = this.user.isAuthenticated;
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.isAuthenticated = this.user.isAuthenticated;
            this.close();
        });
        this.user.events.pipe(filter((e) => e instanceof UserLoginFailedEvent)).subscribe(() => {
            this.isAuthenticated = this.user.isAuthenticated;
        });
        this.isPopUp = data?.isPopUp;
    }
    ngOnInit() {
        this.loginConfig = this.configProvider.provideLoginClientConfig();
        this.loginService.LoginBannerData.subscribe(
            (login: any) => {
                if (login) {
                    this.loginBanner = login;
                }
            },
            (error: any) => {
                console.log(error);
            },
        );
        this.activeRoute.queryParams.subscribe((queryParams) => {
            const navigationPage = (queryParams['hrNavPage'] || '').trim();
            this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
            if (navigationPage == '' || navigationPage == undefined || navigationPage == null) {
                this.showHelpConfig = false;
            } else {
                if (navigationPage == 'todaysRacing') {
                    this.showHelpConfig = false;
                } else this.showHelpConfig = true;
            }
        });
    }
    signUpNavigation() {
        this.loginNavigationService.goToRegistration({ appendReferrer: true });
    }
    login() {
        this.loginDialogService.open({ loginMessageKey: 'horseracing' });
    }
    close() {
        this.dialogRef.close();
    }
    DoNotShowMessage(event: any, isChecked: HTMLInputElement) {
        console.log(isChecked);
        console.log(event);
        localStorage.setItem('IsLoginShow', isChecked?.value?.toString());
    }

    @HostListener('window:orientationchange')
    @HostListener('window:resize')
    IsMobileOrDesktop() {
        this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
    }
}
