import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, UserService } from '@frontend/vanilla/core';

@Component({
    selector: 'hra-home',
    templateUrl: 'home.component.html',
})
export class HomeComponent implements OnInit {
    showButtons: boolean = false; //Remove later, just for dev purpose
    isAuthenticated: boolean;
    height: number;
    isMobile: boolean = false;
    showHelpConfig: boolean = false;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly user: UserService,
        private readonly deviceService: DeviceService,
    ) {
        this.isAuthenticated = this.user.isAuthenticated;
    }

    ngOnInit() {
        // eslint-disable-next-line no-console
        console.log('[HorseRacingPortal]', '[HomeComponent]', '[OnInit]');
        this.IsMobileOrDesktop();
        this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
        this.showButtons = this.route.snapshot.queryParamMap.get('_showButtons') === '1';
    }

    // loginRequest() {
    //     window.postMessage({ messageName: 'requestLogin' }, '*');
    // }
    loginRequest() {
        const targetOrigin = window.location.origin;
        window.postMessage({ messageName: 'requestLogin' }, targetOrigin);
    }
    // logoutRequest() {
    //     window.postMessage({ messageName: 'requestLogout' }, '*');
    // }
    logoutRequest() {
        const targetOrigin = window.location.origin;
        window.postMessage({ messageName: 'requestLogout' }, targetOrigin);
    }
    // updateHeight() {
    //     window.postMessage({ messageName: 'updateHeight', newHeight: this.height + 'px' }, '*');
    // }
    updateHeight() {
        const targetOrigin = window.parent.origin || '*';
        window.postMessage({ messageName: 'updateHeight', newHeight: this.height + 'px' }, targetOrigin);
    }

    @HostListener('window:orientationchange')
    @HostListener('window:resize')
    IsMobileOrDesktop() {
        this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
    }
}
