<div class="PopupBtnAlign">
    <div></div>
    <h1 class="PopupHeader" [innerHtml]="data.header"></h1>
    <div class="btn-close" (click)="close()"><i class="theme-ex"></i></div>
</div>
<div>
    <ol class="HorseInfo" *ngIf="!data.isBetType">
        <li class="HorseListInfo" *ngFor="let item of data.info; index as i; trackBy: trackByBetTypeItems">
            {{ item }}
        </li>
    </ol>
    <ol class="HorseInfo" *ngIf="data.isBetType">
        <li class="HorseListInfo" *ngFor="let item of data.betTypeItems; index as i; trackBy: trackByBetTypeItems">
            <h3>{{ item.title }}</h3>
            <p [innerHtml]="item.description"></p>
            <div class="BetTypeLabel">
                <span class="BetTypeLabel" [innerHtml]="item.info.DifficultyLevelText"></span>
                <span class="BackgroundSet" [innerHtml]="item.info.DifficultyLevelValue"></span>
            </div>
            <div class="BetTypeLabel">
                <span class="BetTypeLabel" [innerHtml]="item.info.PotentialPayOutText"></span>
                <div>
                    <span class="BackgroundSet space" [innerHtml]="item.info.PotentialPayOutValue"></span>
                    <span class="BackgroundDisabled space" [innerHtml]="item.info.PotentialPayOutValue"></span>
                    <span class="BackgroundDisabled space" [innerHtml]="item.info.PotentialPayOutValue"></span>
                    <span class="BackgroundDisabled space" [innerHtml]="item.info.PotentialPayOutValue"></span>
                    <span class="BackgroundDisabled space" [innerHtml]="item.info.PotentialPayOutValue"></span>
                </div>
            </div>
        </li>
    </ol>
</div>
