import { Component, OnInit } from '@angular/core';

import { SeoboxesClientConfig } from '../client-config/client-config.models';
import { ConfigProviderService } from '../client-config/config-provider.service';

@Component({
    selector: 'hrl-seoboxes',
    templateUrl: 'seoboxes.component.html',
})
export class HorseRacingSeoboxesComponent implements OnInit {
    seoBoxesConfig: SeoboxesClientConfig;
    constructor(private configProvider: ConfigProviderService) {}
    ngOnInit() {
        this.seoBoxesConfig = this.configProvider.provideSeoboxesClientConfig();
    }
}
