import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';

import { HorseRacingLoginComponent } from './horse-racing-login.component';

@NgModule({
    declarations: [HorseRacingLoginComponent],
    imports: [CommonModule],
    // entryComponents: [HorseRacingLoginComponent],
    exports: [HorseRacingLoginComponent],
})
export class HorseRacingLoginModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(HorseRacingLoginComponent);
    }
}
