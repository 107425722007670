import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';

import { PageMatrixComponent } from '@frontend/vanilla/features/content';

import { HorseRacingSeoboxesComponent } from './seoboxes.component';

@NgModule({
    imports: [CommonModule, PageMatrixComponent],
    declarations: [HorseRacingSeoboxesComponent],
    exports: [HorseRacingSeoboxesComponent],
    // entryComponents: [HorseRacingSeoboxesComponent]
})
export class HorseRacingSeoboxesModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(HorseRacingSeoboxesComponent);
    }
}
