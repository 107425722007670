import { Injectable, NgZone } from '@angular/core';

import { __READ_CLIENT_CONFIG__ } from './constants';

@Injectable({
    providedIn: 'root',
})
export class EventsHandlerService {
    constructor(private readonly _ngZone: NgZone) {}

    registerHandlers() {
        this._ngZone.runOutsideAngular(() => {
            addEventListener(__READ_CLIENT_CONFIG__, this.readClientConfighandler, false);
        });
    }

    private readClientConfighandler = (customevent: CustomEventInit) => {
        if (customevent.detail && customevent.detail.clientConfig) {
            customevent.detail.clientConfig = (window as any).clientConfig;
        }
    };
}
