import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

import { AuthService, DeviceService, Logger, LoginDialogService, NativeAppService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IPartnerToken } from '../../partner-token.model';
import { TokenBuilderService } from '../../token-builder.service';
import { IframeClientConfig } from '../client-config/client-config.models';
import { ConfigProviderService } from '../client-config/config-provider.service';
import { GeolocationActivatorService } from '../geolocation/services/activator.service';
import { GeolocationCheckService } from '../geolocation/services/geolocation-check.service';

@Component({
    selector: 'hrl-horse-racing-iframe',
    templateUrl: 'horse-racing-iframe.component.html',
    styleUrls: [],
})
export class HorseRacingIframeComponent implements OnInit /*, AfterViewInit */, OnDestroy {
    private isFrameLoaded = false;
    private iframeClientConfig: IframeClientConfig;
    iframeUrl: SafeResourceUrl;
    private token: IPartnerToken;
    private tokenObservable: Subscription;
    private iframe: HTMLIFrameElement;
    isMobile: boolean;

    constructor(
        private readonly sanitizer: DomSanitizer,
        private readonly configProvider: ConfigProviderService,
        private readonly tokenBuilderService: TokenBuilderService,
        private readonly user: UserService,
        private readonly activeRoute: ActivatedRoute,
        private readonly loginDialogService: LoginDialogService,
        private readonly authService: AuthService,
        private readonly geolocationCheckService: GeolocationCheckService,
        private readonly geolocationActivator: GeolocationActivatorService,
        private readonly balanceService: BalancePropertiesService,
        private readonly nativeAppService: NativeAppService,
        private readonly deviceService: DeviceService,
        private readonly logger: Logger,
    ) {}

    ngOnInit() {
        this.iframeClientConfig = this.configProvider.provideIframeClientConfig();
        this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
        this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeClientConfig.url);
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            if (this.isFrameLoaded) {
                this.communicateLoginAndKickOffGeoCoordinator();
            }
        });
    }

    @HostListener('window:message', ['$event']) processMessages(event: MessageEvent) {
        if ((event.data.messageName || '').trim()) {
            this.logger.info(
                '[HorseRacingPortal]',
                '[HorseRacingIframeComponent]',
                '[processMessages]',
                '[Received Message]',
                `[${event.data && (event.data.messageName || '').trim()}]`,
            );

            switch (event.data.messageName) {
                case 'requestLogin':
                    if (!this.user.isAuthenticated) {
                        if (!this.loginDialogService.opened) {
                            this.loginDialogService.open({ loginMessageKey: 'horseracing' });
                        }
                    }
                    break;

                case 'updateBalance':
                    if (this.user.isAuthenticated) {
                        this.balanceService.refresh();
                    }
                    break;

                case 'updateHeight':
                    if (this.isFrameLoaded && this.iframe) {
                        if (!isNaN(parseInt(this.iframe.height)) && !isNaN(parseInt(event.data.newHeight))) {
                            let heightDifference =
                                event.data.newHeight > this.iframe.height
                                    ? event.data.newHeight - parseInt(this.iframe.height)
                                    : parseInt(this.iframe.height) - event.data.newHeight;
                            if (heightDifference > 100) {
                                this.iframe.height = event.data.newHeight;
                            }
                        } else this.iframe.height = event.data.newHeight;
                    }
                    break;

                case 'requestLogout':
                    let reason = event.data.reason;
                    this.logout(reason);
                    break;
                case 'notifyEvent': // Refer integration document. Available since version 1i
                    const eventName = (event.data.eventName || '').trim();
                    const isSuccessful = event.data.parameters.isSuccessful;
                    const numberOfBets = event.data.parameters.numberOfBets;

                    this.nativeAppService.sendToNative({ eventName, parameters: { isSuccessful, numberOfBets } });
                    break;

                case 'loginFailed': // Refer integration document. Available since version 1j
                    // Adjust handling as per product's confirmation. For now keeping logout on our end as the behavior
                    this.logout('loginFailed');
                    break;
            }
        }
    }

    hrLoaded(event: any) {
        if (event.target.src && !this.isFrameLoaded) {
            this.isFrameLoaded = true;
            this.logger.info('[HorseRacingPortal]', '[HorseRacingIframeComponent]', '[hrLoaded]', '[IFrame Loaded]', event);
            this.iframe = event.target as HTMLIFrameElement;
            this.sendInit();

            this.handleHrNavigation();
            if (this.user.isAuthenticated) {
                this.communicateLoginAndKickOffGeoCoordinator();
            }
        }
    }

    private communicateLoginAndKickOffGeoCoordinator() {
        this.sendPartnerLoginEvent();
        this.initGeolocation();
    }

    private sendInit() {
        if (!this.user.isAuthenticated && this.iframe) {
            setTimeout(
                () => {
                    this.iframe?.contentWindow?.postMessage({ messageName: 'init' }, new URL(this.iframeClientConfig.url).origin);
                },
                this.iframeClientConfig?.messageDelay > 5 ? this.iframeClientConfig.messageDelay : 5,
            );
        }
    }

    private handleHrNavigation() {
        if (this.isFrameLoaded) {
            this.activeRoute.queryParams.subscribe((queryParams) => {
                // Time to send a message to nyra iframe
                this.logger.info('[HorseRacingPortal]', '[IntegrationFrame]', '[handleHrNavigation]', '[Query Parameter Change Detected]');
                if (queryParams && queryParams.hrNavPage) {
                    const navigationPage = (queryParams['hrNavPage'] || '').trim();
                    if (navigationPage && this.iframe) {
                        const iframe = this.iframe.contentWindow;
                        setTimeout(
                            () => {
                                this.logger.info(
                                    '[HorseRacingPortal]',
                                    '[IntegrationFrame]',
                                    '[handleHrNavigation]',
                                    navigationPage,
                                    '[Sending Message to Integration Frame]',
                                );
                                iframe?.postMessage(
                                    { messageName: 'changePage', toPage: navigationPage },
                                    new URL(this.iframeClientConfig.url).origin,
                                );
                            },
                            this.iframeClientConfig?.navigationDelay > 5 ? this.iframeClientConfig.navigationDelay : 5,
                        );
                    }
                } else {
                    if (this.isMobile) {
                        const iframe = this.iframe.contentWindow;
                        setTimeout(
                            () => {
                                this.logger.info(
                                    '[HorseRacingPortal]',
                                    '[IntegrationFrame]',
                                    '[handleHrNavigation]',
                                    'todaysRacing',
                                    '[Sending Message to Integration Frame]',
                                );
                                iframe?.postMessage(
                                    { messageName: 'changePage', toPage: 'todaysRacing' },
                                    new URL(this.iframeClientConfig.url).origin,
                                );
                            },
                            this.iframeClientConfig?.navigationDelay > 5 ? this.iframeClientConfig.navigationDelay : 5,
                        );
                    }
                }
            });
        }
    }

    private sendPartnerLoginEvent() {
        this.tokenObservable = this.tokenBuilderService.tokenAvailable.subscribe(
            (token: IPartnerToken) => {
                if (token) {
                    this.token = token;
                    let partnerToken = window.btoa(JSON.stringify(this.token));
                    setTimeout(
                        () => {
                            this.logger.info('[HorseRacingPortal]', '[IntegrationFrame]', '[PARTNER_TOKEN]', JSON.stringify(this.token));
                            this.iframe?.contentWindow?.postMessage(
                                { messageName: 'login', partnerToken: partnerToken },
                                new URL(this.iframeClientConfig.url).origin,
                            );
                        },
                        this.iframeClientConfig?.messageDelay > 5 ? this.iframeClientConfig.messageDelay : 5,
                    );
                }
            },
            (error: any) => {
                this.logger.info(error);
            },
        );
    }

    ngOnDestroy() {
        if (this.tokenObservable) {
            this.tokenObservable.unsubscribe();
        }
        this.isFrameLoaded = false;
    }

    private initGeolocation() {
        if (this.geolocationActivator.isActive) {
            this.geolocationCheckService.startGeolocation();
        }
    }

    private logout(logoutReason?: any): void {
        this.logger.info('[HorseRacingPortal]', '[HorseRacingIframeComponent]', '[logout]', logoutReason);
        this.authService.logout({ redirectAfterLogout: false, isAutoLogout: false }).then(() => {
            setTimeout(
                () => {
                    this.iframe.contentWindow?.postMessage({ messageName: 'logout' }, new URL(this.iframeClientConfig.url).origin);
                },
                this.iframeClientConfig?.messageDelay > 5 ? this.iframeClientConfig.messageDelay : 5,
            );
        });
    }
}
