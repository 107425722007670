import { Injectable } from '@angular/core';

import { ClientConfigService } from '@frontend/vanilla/core';

import {
    GeoLocationClientConfig,
    GlobalClientConfig,
    HelpPopUpClientConfig,
    IframeClientConfig,
    ImageLoadOptimizationClientConfig,
    LoginBannerClientConfig,
    OptimizedTeaserConfig,
    PartnerTokenClientConfig,
    SeoboxesClientConfig,
} from './client-config.models';

@Injectable({
    providedIn: 'root',
})
export class ConfigProviderService {
    iframeClientConfig: IframeClientConfig;
    partnerTokenClientConfig: PartnerTokenClientConfig;
    geoLocationClientConfig: GeoLocationClientConfig;
    globalClientConfig: GlobalClientConfig;
    seoboxesClientConfig: SeoboxesClientConfig;
    imageLoadOptimizationConfig: ImageLoadOptimizationClientConfig;
    optimizedTeaserConfig: OptimizedTeaserConfig;
    loginBannerClientConfig: LoginBannerClientConfig;
    helpPopUpClientConfig: HelpPopUpClientConfig;

    constructor(private clientConfigService: ClientConfigService) {}

    fillClientConfig() {
        this.iframeClientConfig = this.clientConfigService.get(IframeClientConfig);
        this.partnerTokenClientConfig = this.clientConfigService.get(PartnerTokenClientConfig);
        this.geoLocationClientConfig = this.clientConfigService.get(GeoLocationClientConfig);
        this.globalClientConfig = this.clientConfigService.get(GlobalClientConfig);
        this.seoboxesClientConfig = this.clientConfigService.get(SeoboxesClientConfig);
        this.optimizedTeaserConfig = this.clientConfigService.get(OptimizedTeaserConfig);
        this.imageLoadOptimizationConfig = this.clientConfigService.get(ImageLoadOptimizationClientConfig);
        this.loginBannerClientConfig = this.clientConfigService.get(LoginBannerClientConfig);
        this.helpPopUpClientConfig = this.clientConfigService.get(HelpPopUpClientConfig);
    }
    provideIframeClientConfig() {
        return this.iframeClientConfig;
    }
    providePartnerTokenClientConfig() {
        return this.partnerTokenClientConfig;
    }
    provideGeoLocationClientConfig() {
        return this.geoLocationClientConfig;
    }
    provideGlobalClientConfig() {
        return this.globalClientConfig;
    }
    provideSeoboxesClientConfig() {
        return this.seoboxesClientConfig;
    }

    provideOptimizedTeaserConfig() {
        return this.optimizedTeaserConfig;
    }

    provideImageLoadOptimizationClientConfig(): ImageLoadOptimizationClientConfig {
        return this.imageLoadOptimizationConfig;
    }
    provideLoginClientConfig() {
        return this.loginBannerClientConfig;
    }
    provideHelpClientConfig() {
        return this.helpPopUpClientConfig;
    }
}
