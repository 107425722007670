import { Routes } from '@angular/router';

import { VANILLA_LAZY_ROUTES } from '@frontend/loaders/routes';

import { HomeComponent } from './home/home.component';

export const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '{culture}',
                data: {
                    product: 'host',
                    segment: 'main',
                },
                children: [
                    {
                        path: '',
                        component: HomeComponent,
                    },
                    {
                        path: 'horseracing',
                        component: HomeComponent,
                    },
                ],
            },
        ],
    },
    {
        path: '{culture}',
        data: {
            product: 'host',
            segment: 'main',
        },
        children: [...VANILLA_LAZY_ROUTES],
    },
    {
        path: '**',
        loadChildren: () => import('@frontend/vanilla/features/not-found').then((m) => m.ROUTES),
    },
];
