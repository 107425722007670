import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import {
    HorseRacingHelpModule,
    HorseRacingIframeModule,
    HorseRacingLoginModule,
    HorseRacingSeoboxesModule,
    TeaserModule,
} from '@frontend/horseracing';

import { HomeComponent } from './home.component';

@NgModule({
    declarations: [HomeComponent],
    imports: [
        HorseRacingIframeModule,
        CommonModule,
        FormsModule,
        HorseRacingSeoboxesModule,
        TeaserModule,
        HorseRacingSeoboxesModule,
        HorseRacingLoginModule,
        HorseRacingHelpModule,
    ],
    exports: [HomeComponent],
})
export class HomeModule {}
