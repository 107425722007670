import { Injectable } from '@angular/core';

import {
    BalancehHandlerService,
    ConfigProviderService,
    EventsHandlerService,
    GeolocationHandlerService,
    UserEventsBroadcasterService,
} from '@frontend/horseracing';
import { HeaderService, MenuActionsService, OnAppInit } from '@frontend/vanilla/core';
import { ProductActivatorService } from '@frontend/vanilla/shared/product-activation';

@Injectable()
export class AppBootstrapperService implements OnAppInit {
    constructor(
        private readonly productActivatorService: ProductActivatorService,
        private readonly configProvider: ConfigProviderService,
        private readonly eventsHandlerService: EventsHandlerService,
        private readonly userEventsBroadcasterService: UserEventsBroadcasterService,
        private readonly geolocationHandlerService: GeolocationHandlerService,
        private readonly balanceHandlerService: BalancehHandlerService,
        private readonly headerService: HeaderService,
        private readonly menuService: MenuActionsService,
    ) {}

    async onAppInit(): Promise<void> {
        this.configProvider.fillClientConfig();
        await this.productActivatorService.activate('host');
        this.eventsHandlerService.registerHandlers();
        this.userEventsBroadcasterService.register();
        this.geolocationHandlerService.registerHandler();
        this.balanceHandlerService.register();
    }

    myBetsHandler() {
        this.headerService.whenReady.subscribe(() => {
            this.menuService.register('navigate-hrbets-page', (origin: string, itemurl: string, ignore: any, htmlAttributes: any) => {
                const iframe = document.querySelector('#ifrm');
                if (iframe) {
                    const contentChild = (iframe as HTMLIFrameElement).contentWindow;
                    if (contentChild) {
                        if (htmlAttributes && htmlAttributes.hrNavPage)
                            contentChild.postMessage(
                                {
                                    messageName: 'changePage',
                                    toPage: htmlAttributes.hrNavPage,
                                },
                                new URL((iframe as HTMLIFrameElement).src).origin,
                            );
                    }
                }
            });
        });
    }
    myTracksHandler() {
        this.headerService.whenReady.subscribe(() => {
            this.menuService.register('navigate-hrtracks-page', (origin: string, itemurl: string, ignore: any, htmlAttributes: any) => {
                const iframe = document.querySelector('#ifrm');
                if (iframe) {
                    const contentChild = (iframe as HTMLIFrameElement).contentWindow;
                    if (contentChild) {
                        if (htmlAttributes && htmlAttributes.hrNavPage)
                            contentChild.postMessage(
                                {
                                    messageName: 'changePage',
                                    toPage: htmlAttributes.hrNavPage,
                                },
                                new URL((iframe as HTMLIFrameElement).src).origin,
                            );
                    }
                }
            });
        });
    }
}
