import { ClientConfig } from '@frontend/vanilla/core';

@ClientConfig({ key: 'hrIframeUrlsConfig', product: 'horseracing' })
export class IframeClientConfig {
    url: string;
    messageDelay: number;
    navigationDelay: number;
}

@ClientConfig({ key: 'hrPartnerTokenConfig', product: 'horseracing' })
export class PartnerTokenClientConfig {
    integrationPartnerId: number;
    gameType: string;
    serverSide: boolean;
}

@ClientConfig({ key: 'hrGeoLocationConfig', product: 'horseracing' })
export class GeoLocationClientConfig {
    useVanilla: string;
    posAPIEndpoint: string;
    gDSUrl: string;
    initialRetryInterval: number;
    isRetryButtonEnabled: boolean;
    geoCoordinatorBootstrapUrl: string;
    isEnabled: boolean;
    oobeeIntimation: OobeeIntimationModel;
    synchronizationEvent: string;
    synchronizeCoordination: boolean;
    bootstrapAssetPaths: string[];
    //geocomply messages
    downloadAppFromDevice: boolean;
    downloadAppErrorMsgs: string;
    geoComplyMessage: any;
}

export class OobeeIntimationModel {
    isEnabled: boolean;
    toasterId: string;
}

@ClientConfig({ key: 'hrGlobal', product: 'horseracing' })
export class GlobalClientConfig {
    messages: any;
}

@ClientConfig({ key: 'hrSeoBoxesConfig', product: 'horseracing' })
export class SeoboxesClientConfig {
    seoBoxesPathMapper: any;
    enableSeoBox: boolean;
}

@ClientConfig({ key: 'hrOptimizedTeaser', product: 'horseracing' })
export class OptimizedTeaserConfig {
    teaserDefaultHorizontalAlignment: string;
    teaserDefaultImageAlignment: string;
    teaserDefaultVerticalAlignment: string;
    teaserDefaultTermsTextAlignment: string;
    teaserDefaultHorizontalTextAlignment: string;
    teaserOptimization: boolean;
    maxRenderedTeasers: boolean;
    desktopViewTeaserHeight: number;
    mobileViewTeaserHeight: number;
    tabletViewTeaserHeight: number;
    enableTickingForJackpotOnTeasers: boolean;
    enableOptMiniTeasers: boolean;
    teaserDelay: number;
}

@ClientConfig({ key: 'hrImageLoadOptimizationConfig', product: 'horseracing' })
export class ImageLoadOptimizationClientConfig {
    isDynamicResizingEnabled: boolean;
    dynamicResizingQueryString: string;
}

@ClientConfig({ key: 'hrLoginBannerConfig', product: 'horseracing' })
export class LoginBannerClientConfig {
    loginPathMapper: any;
    enableLogin: boolean;
}

@ClientConfig({ key: 'hrHelpPopUpConfig', product: 'horseracing' })
export class HelpPopUpClientConfig {
    enableHelpPopUp: boolean;
}
