<div id="casino-geolocation-installer-container">
    <div id="geolocation-desktop-installer" class="geo-location-popup-container" *ngIf="!isMobileInstaller">
        <div class="geo-title-heading">
            <h1 mat-dialog-title class="geo-title" [textContent]="geocomplyTitle"></h1>
            <span class="theme-ex" id="dialogclosebutton" (click)="close()"></span>
        </div>
        <div mat-dialog-content class="geo-content" [vnDynamicHtml]="geoComplyMessage"></div>
        <div mat-dialog-actions class="geo-buttons">
            <button class="geo-left-button" mat-button (click)="download()" [textContent]="downloadButtonText"></button>
            <button class="geo-right-button" mat-button (click)="continue()" cdkFocusInitial [textContent]="continueButtonText"></button>
        </div>
    </div>
    <div id="geolocation-mobile-installer" class="casino-modal" *ngIf="isMobileInstaller">
        <div class="geolocation-mobile-installer-title-heading">
            <h1 mat-dialog-title class="geolocation-mobile-installer-title" [textContent]="geolocationMobileInstallerTitle"></h1>
            <span class="theme-ex" id="dialogclosebutton" (click)="close()"></span>
        </div>
        <div class="container-overlay">
            <div class="theme-location-pin"></div>
            <div class="container-overlay-header">
                <h2 [textContent]="oobeeInstallerHeadingText"></h2>
                <p [textContent]="oobeeInstallerHeadingInstruction"></p>
            </div>
            <p class="header-text" [textContent]="continuePlayOnAppInstructionText"></p>
            <div class="clearfix">
                <a
                    id="appDownloaderLink"
                    [href]="appDownloadLink"
                    target="_blank"
                    class="btn btn-primary"
                    (click)="appDownloaderClicked()"
                    [textContent]="continuePlayOnAppUserActionText"></a>
            </div>
            <br />
            <div class="container-overlay-break">
                <hr />
                <div class="or-container" [textContent]="playOnAppOrWebPlayerOptionsSeparatorText"></div>
            </div>
            <p class="p-text-middle" [textContent]="continuePlayOnWebInstructionText"></p>
            <div class="oobeeInstallerUserActionsContainer">
                <div id="oobeeInstallerDownloadContainer">
                    <p id="oobeeInstallerDownloadStepText" [textContent]="oobeeInstallerDownloadStepText"></p>
                    <a
                        id="btn-download"
                        class="oobeeInstallerDownloadUserActionButton btn btn-secondary"
                        (click)="download()"
                        [textContent]="oobeeInstallerDownloadUserActionText"></a>
                </div>
                <div id="oobeeInstallerContinueContainer">
                    <p id="oobeeInstallerContinueStepText" [textContent]="oobeeInstallerContinueStepText"></p>
                    <a
                        id="btn-continue"
                        class="oobeeInstallerContinueUserActionButton btn btn-primary"
                        (click)="continue()"
                        [textContent]="oobeeInstallerContinueUserActionText"></a>
                </div>
            </div>
            <div class="container-overlay-footer">
                <div class="geoGuardEnquiryUserActionContainer">
                    <i class="ui-icon theme-info-i" [floatUi]="popper.content" (click)="info()"></i>
                    <span
                        class="geoGuardEnquiryUserActionText"
                        [floatUi]="popper.content"
                        (click)="info()"
                        [textContent]="geoguardEnquiryUserActionText"></span>
                </div>
            </div>
            <div>
                <vn-popper-content cssClass="tooltip-info" #popper>
                    {{ geoGuardDescriptionText }}
                </vn-popper-content>
            </div>
        </div>
    </div>
</div>
<div class="loading" *ngIf="showLoader">
    <span></span>
</div>
