<div
    class="hr-teasers"
    *ngIf="
        filteredteasers?.length &&
        optimizedTeaser?.teaserOptimization &&
        ((showTeaser && isAuthenticated) || (!isAuthenticated && isMobile) || !isMobile)
    ">
    <vn-swiper #carouselRef [swiperOptions]="swiperOptions">
        <div class="swiper-slide" *ngFor="let teaser of filteredteasers; let i = index; trackBy: trackByTeaser">
            <hrl-teaser-item [teaser]="teaser"></hrl-teaser-item>
        </div>
    </vn-swiper>
</div>
