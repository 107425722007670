/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { DeviceManager } from '../../../shared/device-manager.service';
import { CoordinatorStates, IErrorData } from '../coordinator.models';
import { GeolocationCoordinatorService } from './coordinator.service';
import { GeolocationInstallerDialogService } from './installer-dialog.service';

@Injectable({
    providedIn: 'root',
})
export class GeolocationCheckService {
    onCheckClosed: () => void;
    constructor(
        private geolocationCoordinatorService: GeolocationCoordinatorService,
        private geolocationInstallerService: GeolocationInstallerDialogService,
        private readonly deviceManager: DeviceManager,
    ) {
        this.geolocationInstallerService.onDialogClosed = this.onInstallerServiceDialogClosed.bind(this);
    }

    startGeolocation(): void {
        this.geolocationCoordinatorService.onInitialized = (initializationStatus?: CoordinatorStates | null) => {
            console.info('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onInitialized::', initializationStatus);
            if (initializationStatus === CoordinatorStates.INITIALIZED) this.closeGeolocationDialog();
        };

        this.geolocationCoordinatorService.onError = (errorData: IErrorData) => {
            console.error('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onError::', errorData);
            if (errorData && errorData.errorCode == -1111) {
                this.showGeolocationInstallerPopup(
                    (errorData && errorData.additionalInfo && errorData.additionalInfo['appDownloadUrl']) ||
                        this.geolocationCoordinatorService.appDownloadUrl,
                    this.isMobileInstaller(),
                );
                return;
            }
            console.error('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onError::', errorData);
            if (errorData && errorData.errorCode == -1111) {
                const appDownloadUrl = errorData.additionalInfo?.appDownloadUrl;
                const isMobileInstaller = /* logic to determine if it's a mobile installer */ true; // You need to determine this value
                this.showGeolocationInstallerPopup(appDownloadUrl || '', isMobileInstaller);
            }
            this.closeGeolocationDialog();
        };

        this.geolocationCoordinatorService.onUninitialized = () => {
            console.info('[GeolocationCheckService]', 'GeolocationCoordinatorService::', 'onUninitialized::');
        };

        this.geolocationCoordinatorService.initialize({
            showPLCPopup: false,
        });
    }

    closeGeolocationDialog(): void {
        this.geolocationInstallerService.closeDialog();
        console.log('GeolocationInstaller popup closed');
    }

    private showGeolocationInstallerPopup(downloadUrl: string, isMobileInstaller: boolean): void {
        this.geolocationInstallerService.hideLoader();
        if (!this.geolocationInstallerService.isDialogOpen) this.geolocationInstallerService.openDialog(downloadUrl, isMobileInstaller);
    }

    private isMobileInstaller(): boolean {
        return this.deviceManager.isTouch();
    }

    private onInstallerServiceDialogClosed(): void {
        if (this.onCheckClosed) {
            this.onCheckClosed();
        }
    }
}
