/* eslint-disable no-console */
import { Component, Inject, OnInit, TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'hrl-horse-racing-understand',
    templateUrl: 'understand-popup.component.html',
})
export class UnderstandPopupComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected readonly trackByBetTypeItems: TrackByFunction<any> = (index, _) => index;

    constructor(
        public dialogRef: MatDialogRef<UnderstandPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {}
    ngOnInit() {
        console.log(this.data);
    }
    close() {
        this.dialogRef.close();
    }
}
