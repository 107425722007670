import { ModuleWithProviders, NgModule } from '@angular/core';

@NgModule()
export class ApiModule {
    static forRoot(): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
        };
    }
}
