import { Injectable } from '@angular/core';

import {
    UserAutologoutEvent,
    UserEvent,
    UserLoggingInEvent,
    UserLoggingOutEvent,
    UserLoginEvent,
    UserLogoutEvent,
    UserService,
    UserUpdateEvent,
} from '@frontend/vanilla/core';

import { __USER_EVENT__ } from './constants';
import { PlayerSessionInfoService } from './player-session-info.service';
import { TokenBuilderService } from './token-builder.service';

@Injectable({
    providedIn: 'root',
})
export class UserEventsBroadcasterService {
    constructor(
        private readonly userService: UserService,
        private playerSessionService: PlayerSessionInfoService,
        private tokenBuilderService: TokenBuilderService,
    ) {}

    register(): void {
        if (this.userService.isAuthenticated) {
            this.initPartnerTokenCreation();
        }
        this.userService.events.subscribe((userevent: UserEvent) => {
            if (userevent instanceof UserLoginEvent) {
                this.initPartnerTokenCreation();
                dispatchEvent(new CustomEvent(__USER_EVENT__, { detail: { userevent, eventType: 'UserLoginEvent' } }));
            }
            if (userevent instanceof UserLoggingOutEvent) {
                dispatchEvent(new CustomEvent(__USER_EVENT__, { detail: { userevent, eventType: 'UserLoggingOutEvent' } }));
            }
            if (userevent instanceof UserLogoutEvent) {
                this.playerSessionService.resetUserToken();
                dispatchEvent(new CustomEvent(__USER_EVENT__, { detail: { userevent, eventType: 'UserLogoutEvent' } }));
            }
            if (userevent instanceof UserAutologoutEvent) {
                dispatchEvent(new CustomEvent(__USER_EVENT__, { detail: { userevent, eventType: 'UserAutologoutEvent' } }));
            }
            if (userevent instanceof UserUpdateEvent) {
                dispatchEvent(new CustomEvent(__USER_EVENT__, { detail: { userevent, eventType: 'UserUpdateEvent' } }));
            }
            if (userevent instanceof UserLoggingInEvent) {
                dispatchEvent(new CustomEvent(__USER_EVENT__, { detail: { userevent, eventType: 'UserLoggingInEvent' } }));
            }
        });
    }

    initPartnerTokenCreation() {
        this.tokenBuilderService.getToken();
    }
}
