import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './lib/api';

@Injectable({
    providedIn: 'root',
})
export class TeaserService {
    teasers: any;
    private teasersSubject = new BehaviorSubject<any>(null);
    teasersData: Observable<any> = this.teasersSubject.asObservable();
    constructor(public api: ApiService) {
        this.setTeasers();
    }

    setTeasers() {
        this.api.get('nyra/getTeaserdata').subscribe((response) => {
            this.teasers = response;
            this.teasersSubject.next(this.teasers);
        });
    }

    getTeasers() {
        return this.teasers;
    }
}
