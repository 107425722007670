import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { ConfigProviderService } from '../../client-config/config-provider.service';

@Component({
    selector: 'hrl-teaser-item',
    templateUrl: 'teaser-item.component.html',
})
export class TeaserItemComponent implements OnInit, OnChanges {
    @Input() teaser: any;
    teaserBackgroundImageAlignment: string = '';
    teaserBackgroundPosition: number;
    isMobileImage: boolean;
    isTabletView: boolean;
    optimizedTeaserClientConfig: any;
    desktopViewTeaserHeight: any;
    mobileViewTeaserHeight: any;
    tabletViewTeaserHeight: any;
    horizontalAlignment: any;

    constructor(private configProviderService: ConfigProviderService) {}
    ngOnInit() {
        this.isMobileImage = window.innerWidth <= 767 || document.body.offsetWidth <= 767 ? true : false;
        this.isTabletView =
            (window.innerWidth >= 768 || document.body.offsetWidth >= 768) && (window.innerWidth <= 1024 || document.body.offsetWidth <= 1024)
                ? true
                : false;
    }

    ngOnChanges() {
        if (this.teaser.optimizedTeaserAttributes) {
            this.teaserBackgroundImageAlignment = this.teaser.optimizedTeaserAttributes.teaserBackgroundImageAlignment;
            this.teaserBackgroundPosition = this.teaser.optimizedTeaserAttributes.teaserBackgroundPosition;
        }

        this.optimizedTeaserClientConfig = this.configProviderService.provideOptimizedTeaserConfig();
        this.desktopViewTeaserHeight = this.optimizedTeaserClientConfig.desktopViewTeaserHeight
            ? this.optimizedTeaserClientConfig.desktopViewTeaserHeight
            : 350;
        this.mobileViewTeaserHeight = this.optimizedTeaserClientConfig.mobileViewTeaserHeight
            ? this.optimizedTeaserClientConfig.mobileViewTeaserHeight
            : 200;
        this.tabletViewTeaserHeight = this.optimizedTeaserClientConfig.tabletViewTeaserHeight
            ? this.optimizedTeaserClientConfig.tabletViewTeaserHeight
            : 350;
        this.horizontalAlignment = this.optimizedTeaserClientConfig.teaserDefaultHorizontalTextAlignment
            ? this.optimizedTeaserClientConfig.teaserDefaultHorizontalTextAlignment
            : 'center';
    }
}
