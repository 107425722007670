import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { HorseRacingLibModule, TeaserModule } from '@frontend/horseracing';
import { STORE_PREFIX, runOnAppInit } from '@frontend/vanilla/core';

import { AppBootstrapperService } from './app-bootstrapper/app-bootstrapper.service';
import { appRoutes } from './app.routes';
import { HomeModule } from './home/home.module';

@NgModule({
    imports: [BrowserModule, BrowserAnimationsModule, RouterModule.forRoot(appRoutes), HomeModule, TeaserModule, HorseRacingLibModule],
    providers: [runOnAppInit(AppBootstrapperService), { provide: STORE_PREFIX, useValue: 'horseracing.' }],
})
export class AppModule {}
