import { Injectable } from '@angular/core';

import { BalancePropertiesService } from '@frontend/vanilla/features/balance-properties';

import { __BALANCE_REFRESH__ } from './constants';

@Injectable({
    providedIn: 'root',
})
export class BalancehHandlerService {
    constructor(private readonly balanceService: BalancePropertiesService) {}

    register(): void {
        addEventListener(__BALANCE_REFRESH__, () => {
            this.balanceService.refresh();
        });
    }
}
