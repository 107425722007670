import { Injectable } from '@angular/core';

import { DeviceService, NativeAppService } from '@frontend/vanilla/core';

@Injectable({
    providedIn: 'root',
})
export class DeviceManager {
    private isTouchDevice: boolean;

    constructor(
        private nativeAppService: NativeAppService,
        private deviceService: DeviceService,
    ) {}

    checkForTouchDevice() {
        try {
            this.isTouchDevice =
                !this.nativeAppService.isDownloadClient &&
                this.deviceService.isTouch &&
                !window.matchMedia('(pointer: fine)').matches &&
                !navigator.userAgent.match(/Win64/ || /WOW64/);
        } catch (error) {
            this.isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
        }
    }

    isTouch(): boolean {
        this.checkForTouchDevice();
        return this.isTouchDevice;
    }
}
