import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { ApiService } from './lib/api';

@Injectable({
    providedIn: 'root',
})
export class LoginAndHelpService {
    LoginBanner: any;
    HelpPopup: any;
    private loginBannerSubject = new BehaviorSubject<any>(null);
    LoginBannerData: Observable<any> = this.loginBannerSubject.asObservable();
    private helpPopupSubject = new BehaviorSubject<any>(null);
    HelpPopupData: Observable<any> = this.helpPopupSubject.asObservable();
    constructor(public api: ApiService) {
        this.setLoginBannerData();
        this.setHelpPopupData();
    }

    setLoginBannerData() {
        this.api.get('nyra/getLoginBannerData').subscribe((response) => {
            this.LoginBanner = response;
            this.loginBannerSubject.next(this.LoginBanner);
        });
    }

    setHelpPopupData() {
        this.api.get('nyra/getHelpPopupData').subscribe((response) => {
            this.HelpPopup = response;
            this.helpPopupSubject.next(this.HelpPopup);
        });
    }

    getLoginBannerData() {
        return this.LoginBanner;
    }

    getHelpPopupData() {
        return this.HelpPopup;
    }
}
