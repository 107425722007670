/* eslint-disable no-console */
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from './lib/api/api.service';
import { IPlayerSessionInfo } from './player-session-info.model';

@Injectable({
    providedIn: 'root',
})
export class PlayerSessionInfoService {
    private sessionInfo: IPlayerSessionInfo | null;

    constructor(private api: ApiService) {}

    getPlayerSessionToken(): string {
        return this.sessionInfo?.sessionToken ?? '';
    }

    getUserToken(): Observable<IPlayerSessionInfo> {
        return new Observable((observer) => {
            this.api.get('GetUserToken').subscribe(
                (sessionInfo: IPlayerSessionInfo) => {
                    this.setPlayerSessionInfo(sessionInfo);
                    observer.next(sessionInfo);
                },
                (error) => {
                    console.log(error);
                },
            );
        });
    }

    resetUserToken() {
        this.sessionInfo = null;
    }

    setPlayerSessionInfo(sessionInfo: any) {
        this.sessionInfo = sessionInfo;
    }
}
