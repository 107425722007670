/* eslint-disable no-console */
import { Component, OnInit, TrackByFunction } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { filter } from 'rxjs/operators';

import { LoginAndHelpService } from '../../login-and-help.service';
import { HelpPopUpClientConfig, LoginBannerClientConfig } from '../client-config/client-config.models';
import { ConfigProviderService } from '../client-config/config-provider.service';
import { HorseRacingLoginComponent } from '../horse-racing-login/horse-racing-login.component';
import { UnderstandPopupComponent } from './understand-popup/understand-popup.component';

@Component({
    selector: 'hrl-horse-racing-help',
    templateUrl: 'horse-racing-help.component.html',
})
export class HorseRacingHelpComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected readonly trackByMenuItems: TrackByFunction<any> = (index, _) => index;

    helpConfig: HelpPopUpClientConfig;
    helpPopup: any = null;
    popupData: any = null;
    isAuthenticated: boolean;
    showHelpConfig: boolean = false;
    isTracks: boolean = false;
    isMobile: boolean;
    topPosition: string = '10%';
    loginConfig: LoginBannerClientConfig;

    constructor(
        private dialog: MatDialog,
        private activeRoute: ActivatedRoute,
        private configProvider: ConfigProviderService,
        private loginService: LoginAndHelpService,
        private user: UserService,
        private deviceService: DeviceService,
    ) {
        this.isAuthenticated = this.user.isAuthenticated;
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.isAuthenticated = this.user.isAuthenticated;
        });
    }

    ngOnInit() {
        this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
        this.handleLoginPopup();
        this.handleNavigationPage();
        this.setTopPosition();
        this.setHelpPopupData();
    }

    private handleLoginPopup(): void {
        const loginConfig = this.configProvider.provideLoginClientConfig();
        const IsLoginShow: string = localStorage.getItem('IsLoginShow') ?? '';
        if (!this.isAuthenticated && loginConfig?.enableLogin && !this.isMobile && !IsLoginShow) {
            this.dialog.open(HorseRacingLoginComponent, {
                width: '400px',
                data: { isPopUp: true },
            });
        }
    }

    private handleNavigationPage(): void {
        this.activeRoute.queryParams.subscribe((queryParams) => {
            const navigationPage = (queryParams['hrNavPage'] || '').trim();
            this.showHelpConfig = !(navigationPage && navigationPage !== 'todaysRacing');
        });
    }

    private setTopPosition(): void {
        this.topPosition = this.isMobile ? '10%' : '1%';
    }

    private setHelpPopupData(): void {
        this.helpConfig = this.configProvider.provideHelpClientConfig();
        this.loginService.HelpPopupData.subscribe(
            (help: any) => {
                if (help) {
                    this.helpPopup = help;
                }
            },
            (error: any) => {
                console.log(error);
            },
        );
    }

    open(item: any) {
        this.popupData = null;
        const filtered = Object.keys(this.helpPopup);
        for (const value of filtered) {
            if (this.helpPopup[value]['menuTitle'] == item) {
                this.popupData = this.helpPopup[value];
            }
        }
        this.dialog.open(UnderstandPopupComponent, {
            width: '600px',
            position: { top: this.topPosition },
            data: this.popupData,
            panelClass: 'helppopup',
        });
    }

    close(): void {
        this.dialog && this.dialog.closeAll();
    }
}
