import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { HorseRacingHelpComponent } from './horse-racing-help.component';
import { UnderstandPopupComponent } from './understand-popup/understand-popup.component';

@NgModule({
    declarations: [HorseRacingHelpComponent, UnderstandPopupComponent],
    imports: [CommonModule, MatDialogModule],
    // entryComponents: [HorseRacingHelpComponent],
    exports: [HorseRacingHelpComponent, UnderstandPopupComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class HorseRacingHelpModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(HorseRacingHelpComponent);
    }
}
