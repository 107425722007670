import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, NgModule } from '@angular/core';

import { HorseRacingIframeComponent } from './horse-racing-iframe.component';

@NgModule({
    declarations: [HorseRacingIframeComponent],
    imports: [CommonModule],
    // entryComponents: [HorseRacingIframeComponent],
    exports: [HorseRacingIframeComponent],
})
export class HorseRacingIframeModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(HorseRacingIframeComponent);
    }
}
