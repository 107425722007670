import { AfterViewInit, Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { NavigationService } from '@frontend/vanilla/core';

import { GeolocationTrackingService } from '../../../shared/geolocation-tracking.service';
import { ConfigProviderService } from '../../client-config/config-provider.service';
import { GeolocationCoordinatorService } from '../services/coordinator.service';

@Component({
    selector: 'hr-geolocation-installer',
    templateUrl: 'installer.component.html',
})
export class GeolocationInstallerComponent implements AfterViewInit {
    showLoader = false;
    @Input() data: any;

    constructor(
        public readonly dialogRef: MatDialogRef<GeolocationInstallerComponent>,
        private readonly geolocationCoordinatorService: GeolocationCoordinatorService,
        private readonly _domSanitizer: DomSanitizer,
        @Inject(MAT_DIALOG_DATA) private readonly additionalInfo: any,
        private readonly _geolocationTrackingService: GeolocationTrackingService,
        private configProviderService: ConfigProviderService,
        private navigationService: NavigationService,
    ) {}

    get geoComplyMessage(): string {
        return this.configProviderService.provideGeoLocationClientConfig().geoComplyMessage;
    }

    appDownloadLink(): SafeResourceUrl {
        let userAgent = navigator.userAgent;
        let isIOS = /(iPad|iPhone|iPod)/g.test(userAgent);
        let isAndroid = /(Android)/g.test(userAgent);

        if (isIOS) {
            return this._domSanitizer.bypassSecurityTrustUrl('javascript:void(0)');
        } else if (isAndroid) {
            return this.getGlobalConfig().messages.AndroidAppDownloadLink;
        } else {
            return '';
        }
    }

    get continueButtonText(): string {
        return this.getGlobalConfig().messages['GeoComplyContinueButtonText'];
    }

    get continuePlayOnAppInstructionText(): string {
        return this.getGlobalConfig().messages['ContinuePlayOnAppInstructionText'] || 'Continue play on our app, with automatic geolocation';
    }

    get continuePlayOnAppUserActionText(): string {
        return this.getGlobalConfig().messages['ContinuePlayOnAppUserActionText'] || 'CONTINUE PLAY ON PLAYMGM APP';
    }

    get continuePlayOnWebInstructionText(): string {
        return (
            this.getGlobalConfig().messages['ContinuePlayOnWebInstructionText'] ||
            'To play on web, Geoguard needs to be installed and running on your device'
        );
    }

    get oobeeInstallerDownloadUserActionText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerDownloadUserActionText'] || 'INSTALL OR OPEN GEOGUARD';
    }

    get oobeeInstallerContinueUserActionText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerContinueUserActionText'] || 'GEOGUARD IS INSTALLED AND RUNNING';
    }

    get downloadButtonText(): string {
        return this.getGlobalConfig().messages['GeoComplyDownloadButtonText'];
    }

    get geocomplyText(): string {
        return this.getGlobalConfig().messages['GeoComplyText'];
    }
    get geocomplyTitle(): string {
        return this.getGlobalConfig().messages['GeoComplyTitle'];
    }

    get geoguardEnquiryUserActionText(): string {
        return this.getGlobalConfig().messages['GeoguardEnquiryUserActionText'] || 'WHAT IS GEOGUARD?';
    }

    get oobeeInstallerDownloadStepText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerDownloadStepText'] || 'Step 1';
    }

    get oobeeInstallerContinueStepText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerContinueStepText'] || 'Step 2';
    }

    get geolocationMobileInstallerTitle(): string {
        return this.getGlobalConfig().messages['GeolocationMobileInstallerTitle'] || 'LOCATION';
    }

    get isMobileInstaller(): boolean {
        if (this.data && this.data.isMobileInstaller) {
            return this.data && this.data.isMobileInstaller;
        } else {
            return this.additionalInfo && this.additionalInfo.isMobileInstaller;
        }
    }

    get oobeeInstallerHeadingText(): string {
        return this.getGlobalConfig().messages['OobeeInstallerHeadingText'] || 'Want to continue playing?';
    }

    get oobeeInstallerHeadingInstruction(): string {
        return (
            this.getGlobalConfig().messages['OobeeInstallerHeadingInstruction'] ||
            'Before you launch a game we are required by law to confirm your location.'
        );
    }

    get playOnAppOrWebPlayerOptionsSeparatorText(): string {
        return this.getGlobalConfig().messages['PlayOnAppOrWebPlayerOptionsSeparatorText'] || 'OR';
    }

    get geoGuardDescriptionText(): string {
        return (
            this.getGlobalConfig().messages['GeoGuardDescriptionText'] ||
            `GeoGuard, a trusted 3rd party partner, allows you to obtain location data from your Android device. One time only install, launch every time you want to place a bet on web`
        );
    }
    continue(): void {
        this.showLoader = true;
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Step 2 - Geoguard is Intalled and running - CTA Clicked');
        }
        this.geolocationCoordinatorService.initialize({
            showPLCPopup: true,
        });
    }

    download(): void {
        this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Step 1 - install or Open GeoGuard - CTA Clicked');

        const url = this.data?.downloadUrl || this.additionalInfo?.downloadUrl;

        window.open(url || '', this.isMobileInstaller ? '_blank' : '_self');
    }

    close(): void {
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Pop-up_Close');
        }
        this.dialogRef && this.dialogRef.close();
        if (
            this.navigationService.location.search.has('specialproduct') &&
            this.navigationService.location.search.get('specialproduct') === 'globalsearch'
        ) {
            const returnUrl = this.navigationService.location.search.has('returnurl')
                ? this.navigationService.location.search.get('returnurl')
                : this.navigationService.goToLastKnownProduct();
            if (returnUrl) {
                this.navigationService.goTo(returnUrl);
            }
        }
    }

    appDownloaderClicked(): void {
        let appDownloadLink = '';
        let appId = this.getGlobalConfig().messages.AndroidAppID ? this.getGlobalConfig().messages.AndroidAppID : '';
        if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
            appDownloadLink = this.getGlobalConfig().messages.iOSAppDownloadLink;
            appId = this.getGlobalConfig().messages.iOSAppID ? this.getGlobalConfig().messages.iOSAppID : '';
        }
        this._geolocationTrackingService.trackPosition('GeoLoc_Pop-up how to', 'Continue to play on an APP_ CTA Clicked', appId);
        if (!appDownloadLink) {
            return;
        }
        window.open(appDownloadLink, '_blank');
    }

    hideLoader(): void {
        this.showLoader = false;
    }

    info() {
        if (this.isMobileInstaller) {
            this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'InfoTool - What is Geoguard - CTA Clicked');
        }
    }

    ngAfterViewInit() {
        this._geolocationTrackingService.track('GeoLoc_Pop-up how to', 'Pop-up_Loaded');
    }

    getGlobalConfig(): any {
        const globalConfig = this.configProviderService.provideGlobalClientConfig();
        return typeof globalConfig === 'object' ? globalConfig : {};
    }
}
