/// <reference types="@geolocation/oobee" />
export type Optional<Type> = GCOobee.Optional<Type>;

export type OnInitialized<K> = Optional<(initializationStatus?: Optional<K>) => void>;
export type OnUninitialized = Optional<() => void>;

export const enum CoordinatorStates {
    NOTINITIALIZED = 'NOTINITIALIZED',
    INITIALIZING = 'INITIALIZING',
    INITIALIZED = 'INITIALIZED',
    ERROR = 'ERROR',
    LOCATIONAVAILABLE = 'LOCATIONAVAILABLE',
    PROBING = 'PROBING',
}

export type PLCIdentifier = 'geocomply' | 'oobeeAndroid' | 'oobeeiOS';

export interface IPLCProbeResult {
    readonly status: boolean;
    readonly additionalInfo?: Object;
}

export interface ICoordinatorProbeResult extends IPLCProbeResult {
    readonly coordinatorState: CoordinatorStates;
}

export type OnProbed = (probeResult: ICoordinatorProbeResult) => void;

export interface IOnError {
    onError: OnError;
}
export interface IErrorData {
    errorCode: number;
    errorMessage: string;
    errorType?: ErrorTypes;
    additionalInfo?: { [key: string]: any };
}
export enum ErrorTypes {
    APP_ERROR = 'APPLICATION_ERROR',
    COMM_ERROR = 'COMMUNICATION_ERROR',
    NETWORK_ERROR = 'NETWORK_ERROR',
    DATA_ERROR = 'DATA_ERROR',
    CONFIG_ERROR = 'CONFIGURATION_ERROR',
    FATAL = 'FATAL',
}
export type OnError = Optional<(errorData: IErrorData) => void>;

export interface IIntegrationApp {
    readonly appDownloadUrl: string;
    onInitialized: OnInitialized<CoordinatorStates>;
    onError: OnError;
    onProbed: OnProbed;
    onUninitialized: OnUninitialized;
    initialize(input?: IInitializationInput): void;
    probe(input?: IInitializationInput): void;
    uninitialize(): void;
}
// export  class IntegrationApp implements IIntegrationApp {
//     private readonly configuration;
//     private readonly geolocationCoordinator;
//     private readonly installerSettings;
//     constructor(queryParameterReader?: QueryParameterReader, configuration?: IConfigurationProvider, //IConfigurationProvider,
//     installerSettingsSelector?: InstallerSettingsSelector);
//     onError: OnError;
//     onInitialized: OnInitialized<CoordinatorStates>;
//     onProbed: OnProbed;
//     onUninitialized: OnUninitialized;
//     initialize(input?: IInitializationInput): void;
//     uninitialize(): void;
//     probe(input?: IInitializationInput): void;
//     readonly appDownloadUrl: string;
//     private formDownloadUrl;
//     private getOSVersion;
//     private _onInitialized;
//     private _onUninitialized;
//     private _onError;
//     private _onProbed;
//     private reifyConfig;
// }
export interface IInitializationInput {
    readonly session?: string;
    readonly channelId?: string;
    readonly plcIdentifier?: PLCIdentifier;
    readonly showPLCPopup?: boolean;
    readonly productId?: string;
}

export enum RetryType {
    NonRetry = 0,
    Retry = 1,
}

export interface GDSResponse {
    locationStatus: string;
    isLocationAvailable: boolean;
    errorCode: number;
    errorMsg: string;
    troubleShooterMessages?: { tsMsgs?: GDSTsMsg[] };
}

export interface GDSTsMsg {
    rule: string;
    retry: number;
    message: string;
}
