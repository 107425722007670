import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ConfigProviderService } from '../../client-config/config-provider.service';

@Injectable({
    providedIn: 'root',
})
export class GeolocationActivatorService {
    constructor(
        //private geolocationConfiguration: GeolocationClientConfig,
        private configProviderService: ConfigProviderService,
        private readonly activatedRoute: ActivatedRoute,
    ) {}
    get isActive(): boolean {
        const geolocationConfiguration = this.configProviderService.provideGeoLocationClientConfig();
        let isDisabledViaRoute = false;
        const disableFeaureQueryParameter = this.activatedRoute.snapshot.queryParamMap.get('_disableFeature');
        isDisabledViaRoute =
            disableFeaureQueryParameter
                ?.trim()
                .split(',')
                .some((queryParameterValue) => queryParameterValue.toLowerCase() === 'geolocation') ?? false;
        return !isDisabledViaRoute && geolocationConfiguration.isEnabled;
    }
}
