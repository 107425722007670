/* eslint-disable no-console */
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { AppRoutingModule } from './app-routing.module';
import { AppModule } from './app.module';

if (process.env.NODE_ENV === 'prod') {
    enableProdMode();
}
performance.mark('app-boot-start');
bootloader().then(() => {
    performance.mark('app-client-config-ready');
    performance.measure('client-config', 'app-boot-start', 'app-client-config-ready');
    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(AppRoutingModule),
            provideAnimations(),
            provideVanillaCore(),
            provideLoaders(),
            importProvidersFrom(AppModule),
        ],
    })
        .then(() => {
            performance.mark('app-boot-end');
            performance.measure('app-boot', 'app-boot-start', 'app-boot-end');
            // eslint-disable-next-line no-console
        })
        .catch((err) => console.error(err));
});
