<ng-container *ngIf="showButtons">
    <button *ngIf="!isAuthenticated" (click)="loginRequest()">Login Request</button>
    <input type="number" [(ngModel)]="height" placeholder="Height" />
    <button *ngIf="isAuthenticated" (click)="logoutRequest()">Logout Request</button>
    <button (click)="updateHeight()">Update Height</button>
</ng-container>
<hrl-teaser></hrl-teaser>
<hrl-horse-racing-login *ngIf="isMobile"></hrl-horse-racing-login>
<hrl-horse-racing-help></hrl-horse-racing-help>
<hrl-horse-racing-iframe></hrl-horse-racing-iframe>
<hrl-seoboxes></hrl-seoboxes>
