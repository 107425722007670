import { ModuleWithProviders, NgModule } from '@angular/core';

import { ClientConfigService } from '@frontend/vanilla/core';

import * as models from './client-config.models';

@NgModule()
export class ClientConfigModule {
    static forRoot(): ModuleWithProviders<ClientConfigModule> {
        return {
            ngModule: ClientConfigModule,
            providers: [
                { provide: models.IframeClientConfig, deps: [ClientConfigService], useFactory: iframeClientConfigFactory },
                { provide: models.PartnerTokenClientConfig, deps: [ClientConfigService], useFactory: partnerTokenClientConfigFactory },
                { provide: models.GeoLocationClientConfig, deps: [ClientConfigService], useFactory: geoLocationClientConfigFactory },
                { provide: models.GlobalClientConfig, deps: [ClientConfigService], useFactory: globalClientConfig },
                { provide: models.SeoboxesClientConfig, deps: [ClientConfigService], useFactory: seoboxesClientConfig },
            ],
        };
    }
}

export function iframeClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.IframeClientConfig);
}
export function partnerTokenClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.PartnerTokenClientConfig);
}
export function geoLocationClientConfigFactory(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GeoLocationClientConfig);
}
export function globalClientConfig(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.GlobalClientConfig);
}
export function seoboxesClientConfig(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.SeoboxesClientConfig);
}

export function loginBannerClientConfig(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.LoginBannerClientConfig);
}
export function helpPopUpClientConfig(clientConfigService: ClientConfigService) {
    return clientConfigService.get(models.HelpPopUpClientConfig);
}
