import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { PipesModule } from '../shared/pipes/pipes.module';
import { ApiModule } from './api/api.module';
import { ClientConfigModule } from './client-config/client-config.module';
import { GeolocationValidationErrorModule } from './geolocation/geo-location-error/validation-error.module';
import { GeolocationInstallerModule } from './geolocation/installer/installer.module';
import { HorseRacingHelpModule } from './horse-racing-help/horse-racing-help.module';
import { HorseRacingIframeModule } from './horse-racing-iframe/horse-racing-iframe.module';
import { HorseRacingLoginModule } from './horse-racing-login/horse-racing-login.module';
import { HorseRacingSeoboxesModule } from './seoboxes/seoboxes.module';
import { TeaserModule } from './teasers/teaser.module';

@NgModule({
    imports: [
        CommonModule,
        HorseRacingIframeModule,
        HorseRacingLoginModule,
        HorseRacingHelpModule,
        ClientConfigModule.forRoot(),
        ApiModule.forRoot(),
        MatDialogModule,
        GeolocationInstallerModule.forRoot(),
        GeolocationValidationErrorModule.forRoot(),
        HorseRacingSeoboxesModule,
        TeaserModule,
        PipesModule,
    ],
    exports: [
        HorseRacingIframeModule,
        HorseRacingLoginModule,
        HorseRacingHelpModule,
        ClientConfigModule,
        ApiModule,
        HorseRacingSeoboxesModule,
        TeaserModule,
        PipesModule,
    ],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: {} },
    ],
})
export class HorseRacingLibModule {}
