import { Component, Inject, OnInit, TrackByFunction } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ConfigProviderService } from '../../client-config/config-provider.service';
import { GDSTsMsg, RetryType } from '../coordinator.models';
import { GeoLocationValidationService } from '../services/geolocation-validation.service';

export enum TriggerGeoLocationOption {
    EventName = 'TRIGGER_GEO_LOCATION',
    Action = 'fetchLocation',
}

export enum GeoLocationOptionFetchType {
    NativeCcb = 'NATIVE_CCB',
    Gds = 'GDS',
}

@Component({
    selector: 'hr-geo-location-error',
    templateUrl: 'geo-location-error.html',
})
export class GeoLocationErrorComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected readonly trackByErrorMessages: TrackByFunction<GDSTsMsg> = (index, _) => index;

    geoLocationErrorMessages: GDSTsMsg[] = [];
    shouldRetryLocation = false;
    geolocationConfig: any;
    errorMessages: GDSTsMsg[] = [];

    constructor(
        public readonly dialogRef: MatDialogRef<GeoLocationErrorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private geoLocationValidationService: GeoLocationValidationService,
        private configProviderService: ConfigProviderService,
    ) {}

    ngOnInit(): void {
        this.errorMessages = this.data.errorMessages;
        this.geolocationConfig = this.configProviderService.provideGeoLocationClientConfig();
        this.checkGeoLocationError();
    }

    getGlobalConfig(): any {
        return this.configProviderService.provideGlobalClientConfig();
    }

    get geoComplyErrorHeader(): string {
        return this.getGlobalConfig().messages['GeoComplyErrorHeader'] || 'We Cannot Place Your Bet';
    }

    get turnOnWiFiDescription(): string {
        return this.getGlobalConfig().messages['TurnOnWiFiDescription'] || 'Please turn on your devices WiFi';
    }

    get addressAboveItemsDescription(): string {
        return this.getGlobalConfig().messages['AddressAboveItemsDescription'] || 'Please address the items above, then try  again';
    }

    get recheckLocation(): string {
        return this.getGlobalConfig().messages['RecheckLocation'] || 'RECHECK LOCATION';
    }

    get ok(): string {
        return this.getGlobalConfig().messages['Ok'] || 'OK';
    }

    get invalidLocation(): string {
        return this.getGlobalConfig().messages['InvalidLocation'] || 'Ensure you are well within a permitted area to place your bet successfully';
    }

    close(): void {
        this.dialogRef && this.dialogRef.close();
    }

    reCheckLocation(): void {
        this.geoLocationValidationService.reCheckLocationClicked.next(true);
        this.close();
    }

    private checkGeoLocationError(): void {
        if (!this.errorMessages.length) {
            this.geoLocationErrorMessages.push({
                rule: '',
                retry: RetryType.NonRetry,
                message: this.invalidLocation,
            });
            return;
        }
        this.geoLocationErrorMessages = [...this.errorMessages];
        this.shouldRetryLocation =
            this.geolocationConfig.isRetryButtonEnabled && this.errorMessages.some((errMsg) => errMsg.retry === RetryType.Retry);
    }

    // private fetchLocationDetailsfromNativeEvent(): void {
    //     //this.doTracking(GeoLocationOptionFetchType.NativeCcb);
    //     this.nativeAppService.sendToNative({
    //         eventName: TriggerGeoLocationOption.EventName,
    //         parameters: { action: TriggerGeoLocationOption.Action },
    //     });
    // }

    // private doTracking(geolocationFetchType: string): void {
    // 	this.trackingService.track(trackingConstants.EVENT_TRIGGER_GEO_FETCH, {
    // 		[trackingConstants.GEOLOCATION_FETCH_TYPE]: geolocationFetchType,
    // 	});
    // }
}
