/* eslint-disable no-console */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { AppInfoConfig, ClaimsService } from '@frontend/vanilla/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { GeolocationHandlerService } from './geolocation-handler.service';
import { ApiService } from './lib/api';
import { PartnerTokenClientConfig } from './lib/client-config/client-config.models';
import { ConfigProviderService } from './lib/client-config/config-provider.service';
import { IPartnerToken } from './partner-token.model';
import { PlayerSessionInfoService } from './player-session-info.service';

@Injectable({
    providedIn: 'root',
})
export class TokenBuilderService implements OnDestroy {
    private partnerToken: IPartnerToken;
    private partnerTokenAvailable: boolean = false;
    sessionInfoPlaceholder: string = 'b={0}|c={1}|gt={2}|p={3}|psi={4}';
    private tokenBuiltSubject = new BehaviorSubject<IPartnerToken | null>(null);
    tokenAvailable: Observable<IPartnerToken | null> = this.tokenBuiltSubject.asObservable();
    locationSubscription: Subscription;
    constructor(
        private configProvider: ConfigProviderService,
        private claimsService: ClaimsService,
        private geolocationHandlerService: GeolocationHandlerService,
        private appInfoConfig: AppInfoConfig,
        public api: ApiService,
        private playerSessionService: PlayerSessionInfoService,
        private http: HttpClient,
    ) {}

    buildTokenwithVanilla() {
        this.locationSubscription = this.geolocationHandlerService.locationAvailable.subscribe(
            async (isLocationAvailable) => {
                if (isLocationAvailable) {
                    const partnerTokenConfig: PartnerTokenClientConfig = this.configProvider.providePartnerTokenClientConfig();
                    this.partnerToken = {
                        integrationPartnerId: partnerTokenConfig.integrationPartnerId, // <NYRAProvidedStaticValue> Config from Dynacon
                        currency: this.claimsService.get('currency') ?? '', // <MemberCurrency>", Claims
                        partnerUsername: this.claimsService.get('http://api.bwin.com/v3/user/pg/nameidentifier') ?? '', // <MGMUniqueMemberIdentifier> PGAccountId from Claims
                        state: this.claimsService.get('stateorprovince') ?? '', // <MemberState>, Claims
                        zipcode: this.claimsService.get('postalcode') ?? '', // <MemberZipCode>", Claims
                        location: this.geolocationHandlerService.getLocation(), // GeolocationService of Vanilla
                        issuedAt: new Date(), // <DateTime YYYYMM-DDThh:mm:sssZ>", current datetime
                        signature: '', // "sha256BasedDigitalSignatureOfPartnertoken // Generated using signature APIs
                        arbitrarySessionInformation: this.getSessionInfo(partnerTokenConfig.gameType), //
                    };
                    this.getTokenSignature().subscribe((response) => {
                        this.updateSignatureInToken(response.signature);
                        this.setTokenAvailability(true);
                        this.tokenBuiltSubject.next(this.partnerToken);
                    });
                }
            },
            (error: any) => {
                console.log('Unable to retrieve Location, ' + error?.error?.message);
                this.buildToken(0, 0, 0);
            },
        );
    }

    buildTokenwithPOS() {
        this.http.get(this.configProvider.geoLocationClientConfig.posAPIEndpoint).subscribe(
            (res: any) => {
                if (res) {
                    const latitude = res.latitude;
                    const longitude = res.longitude;
                    this.buildToken(500, latitude, longitude);
                }
            },
            (error: any) => {
                console.log('Unable to retrieve Location, ' + error?.error?.message);
                this.buildToken(0, 0, 0);
            },
        );
    }

    buildTokenWithoutLocation() {
        const location = { accuracy: 0, latitude: 0, longitude: 0 };
        this.api.post('partnertoken/build', location).subscribe((token) => {
            this.partnerToken = token;
            this.setTokenAvailability(true);
            this.tokenBuiltSubject.next(this.partnerToken);
        });
    }

    buildTokenOnServer() {
        (async () => {
            try {
                const fetchedResponse = await fetch(
                    new URL(decodeURIComponent(this.configProvider.geoLocationClientConfig.posAPIEndpoint || ''), location.origin).href,
                    { method: 'GET' },
                );
                const xmlResponse = await fetchedResponse.text();

                const parser = new DOMParser();
                const dom = parser.parseFromString(xmlResponse, 'application/xml');
                if (dom.documentElement.hasAttribute('latitude') && dom.documentElement.hasAttribute('longitude')) {
                    const latitude = +(dom.documentElement.getAttribute('latitude') || '');
                    const longitude = +(dom.documentElement.getAttribute('longitude') || '');

                    if (!isNaN(latitude) && !isNaN(longitude)) {
                        const location = {
                            accuracy: 500,
                            latitude,
                            longitude,
                        };
                        this.api.post('partnertoken/build', location).subscribe((token) => {
                            this.partnerToken = token;
                            this.setTokenAvailability(true);
                            this.tokenBuiltSubject.next(this.partnerToken);
                        });
                    }
                } else {
                    console.log(
                        '[RacingExtension]',
                        '[TokenBuilder]',
                        '[LocationFetcher]',
                        'Building partner token with no location as necessary location paramaters unavailable',
                    );
                    this.buildTokenWithoutLocation();
                }
            } catch (error) {
                console.log('Unable to retrieve Location, ' + error?.error?.message);
                this.buildTokenWithoutLocation();
            }
        })();
    }

    buildTokenServerSide() {
        this.buildTokenOnServer();
    }

    buildToken(accuracy: number, latitude: number, longitude: number) {
        const partnerTokenConfig: PartnerTokenClientConfig = this.configProvider.providePartnerTokenClientConfig();
        this.partnerToken = {
            integrationPartnerId: partnerTokenConfig.integrationPartnerId, // <NYRAProvidedStaticValue> Config from Dynacon
            currency: this.claimsService.get('currency') ?? '', // <MemberCurrency>", Claims
            partnerUsername: this.claimsService.get('https://api.bwin.com/v3/user/pg/nameidentifier') ?? '', // <MGMUniqueMemberIdentifier> PGAccountId from Claims
            state: this.claimsService.get('stateorprovince') ?? '', // <MemberState>, Claims
            zipcode: this.claimsService.get('postalcode') ?? '', // <MemberZipCode>", Claims
            location: {
                accuracy: accuracy,
                latitude: latitude,
                longitude: longitude,
            }, // GeolocationService of Vanilla
            issuedAt: new Date(), // <DateTime YYYYMM-DDThh:mm:sssZ>", current datetime
            signature: '', // "sha256BasedDigitalSignatureOfPartnertoken // Generated using signature APIs
            arbitrarySessionInformation: this.getSessionInfo(partnerTokenConfig.gameType), //
        };
        this.getTokenSignature().subscribe((response) => {
            this.updateSignatureInToken(response.signature);
            this.setTokenAvailability(true);
            this.tokenBuiltSubject.next(this.partnerToken);
        });
    }

    getSessionInfo(gameType: string): string {
        this.sessionInfoPlaceholder = this.sessionInfoPlaceholder.replace('{0}', this.appInfoConfig.brand);
        this.sessionInfoPlaceholder = this.sessionInfoPlaceholder.replace('{1}', this.appInfoConfig.channel);
        this.sessionInfoPlaceholder = this.sessionInfoPlaceholder.replace('{2}', gameType); // replace GameType later
        this.sessionInfoPlaceholder = this.sessionInfoPlaceholder.replace('{3}', this.appInfoConfig.product);
        this.sessionInfoPlaceholder = this.sessionInfoPlaceholder.replace('{4}', this.playerSessionService.getPlayerSessionToken());
        return this.sessionInfoPlaceholder;
    }

    getTokenSignature() {
        return this.api.post('sign/digital', { content: JSON.stringify(this.partnerToken) }, { prefix: '/signature' });
    }

    updateSignatureInToken(sign: string) {
        this.partnerToken.signature = sign;
    }

    setTokenAvailability(available: boolean) {
        this.partnerTokenAvailable = available;
    }

    getTokenAvailability() {
        return this.partnerTokenAvailable;
    }

    getToken() {
        if (this.getTokenAvailability()) {
            return this.partnerToken;
        } else {
            if (this.configProvider.geoLocationClientConfig.useVanilla) {
                this.buildTokenwithVanilla();
            } else {
                this.configProvider.partnerTokenClientConfig.serverSide ? this.buildTokenServerSide() : this.buildTokenwithPOS();
            }
        }
        return undefined;
    }

    ngOnDestroy(): void {
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
    }
}
