import { CommonModule } from '@angular/common';
import { ApplicationRef, DoBootstrap, ModuleWithProviders, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

import { NgxFloatUiModule } from 'ngx-float-ui';

import { GeoLocationErrorComponent } from './geo-location-error.component';

@NgModule({
    imports: [MatDialogModule, CommonModule, NgxFloatUiModule],
    declarations: [GeoLocationErrorComponent],
    exports: [GeoLocationErrorComponent],
    // entryComponents: [GeoLocationErrorComponent],
    providers: [
        { provide: MAT_DIALOG_DATA, useValue: {} },
        { provide: MatDialogRef, useValue: {} },
    ],
})
export class GeolocationValidationErrorModule implements DoBootstrap {
    ngDoBootstrap(appRef: ApplicationRef) {
        appRef.bootstrap(GeoLocationErrorComponent);
    }

    public static forRoot(): ModuleWithProviders<GeolocationValidationErrorModule> {
        return {
            ngModule: GeolocationValidationErrorModule,
            providers: [MatDialogModule],
        };
    }
}
