/* eslint-disable no-console */
import { Component, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DeviceService, UserLoginEvent, UserService } from '@frontend/vanilla/core';
import { SwiperComponent } from '@frontend/vanilla/shared/swiper';
import { filter } from 'rxjs/operators';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

import { TeaserService } from '../../teaser.service';
import { ConfigProviderService } from '../client-config/config-provider.service';
import { TeaserItemComponent } from './teaser-item/teaser-item.component';

@Component({
    selector: 'hrl-teaser',
    templateUrl: 'teaser.component.html',
})
export class TeaserComponent implements OnInit {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected readonly trackByTeaser: TrackByFunction<TeaserItemComponent> = (index, _) => index;

    @ViewChild('carouselRef') carouselRef: SwiperComponent;
    teasers: any;
    filteredteasers: any = [];
    arrows: boolean = true;
    optimizedTeaser: any;
    isAuthenticated: boolean;
    showTeaser: boolean = true;
    isTracks: boolean = false;
    isMobile: boolean;
    loop: boolean = true;

    swiperOptions: SwiperOptions = {
        modules: [Navigation, Pagination, Autoplay],
        loop: true,
        autoHeight: true,
        allowTouchMove: false,
        spaceBetween: 1,
        autoplay: {
            delay: 1000,
            disableOnInteraction: false,
            // pauseOnMouseEnter: true
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            el: '.swiper-pagination',
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    };

    constructor(
        private activeRoute: ActivatedRoute,
        private user: UserService,
        private teaserService: TeaserService,
        private deviceService: DeviceService,
        private configProviderService: ConfigProviderService,
    ) {
        this.isAuthenticated = this.user.isAuthenticated;
        this.user.events.pipe(filter((e) => e instanceof UserLoginEvent)).subscribe(() => {
            this.isAuthenticated = this.user.isAuthenticated;
        });
    }

    ngOnInit() {
        this.isMobile = this.deviceService.isMobile || this.deviceService.isMobilePhone || this.deviceService.isTablet;
        this.activeRoute.queryParams.subscribe((queryParams) => {
            const navigationPage = (queryParams['hrNavPage'] || '').trim();
            if (!navigationPage) {
                this.showTeaser = true;
            } else {
                if (navigationPage == 'todaysRacing') {
                    this.showTeaser = true;
                } else this.showTeaser = false;
            }
        });
        this.optimizedTeaser = this.configProviderService.provideOptimizedTeaserConfig();
        this.swiperOptions.autoplay = { delay: this.optimizedTeaser.teaserDelay, disableOnInteraction: false };

        this.teaserService.teasersData.subscribe(
            (teasers: any) => {
                if (teasers) {
                    this.teasers = teasers;
                    this.teasers?.teaserSettings?.data?.fields?.teasers?.forEach((teaserItem: any) => {
                        const teaserData = this.teasers?.teaserItems.find((x: any) => x?.data?.metadata?.id?.itemName == teaserItem?.itemName);
                        if (teaserData) {
                            this.filteredteasers.push(teaserData);
                            setTimeout(() => {
                                if (this.carouselRef && this.carouselRef.swiper) {
                                    this.carouselRef.swiper.update();
                                    this.carouselRef.swiper.slideTo(0);
                                }
                            }, 100);
                        }
                    });
                }
            },
            (error: any) => {
                console.log(error);
            },
        );
    }
}
